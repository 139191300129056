import { render, staticRenderFns } from "./KpiList.vue?vue&type=template&id=f8ee8702&scoped=true"
import script from "./KpiList.vue?vue&type=script&lang=js"
export * from "./KpiList.vue?vue&type=script&lang=js"
import style0 from "./KpiList.vue?vue&type=style&index=0&id=f8ee8702&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ee8702",
  null
  
)

export default component.exports