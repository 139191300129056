/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

import Layout from '@/components/layouts/Layout.vue'

import UserProfile from '@/views/utils/UserProfile.vue'
import Signin from '@/views/utils/Signin.vue'

import multiguard from 'vue-router-multiguard'
import { requireAuth, requireAdmin, requireAdminPreviewing } from '@/utils/auth'

// User views
import Index from '@/views/Index.vue'
import Acquereurs from '@/views/Acquereurs'
import Impayes from '@/views/Impayes'
import Parametres from '@/views/Parametres'
import Psp from '@/views/Psp'
import Reconciliation from '@/views/Reconciliation'

// Admin views
import IndexAdmin from '@/views/admin/Index.vue'
import Merchants from '@/views/admin/Merchants'
import Organisms from '@/views/admin/Organisms'
import Users from '@/views/admin/Users'
import Files from '@/views/admin/Files'
import Devises from '@/views/admin/Devises'
import BinMapping from '@/views/admin/BinMapping'
import PreviewConsolidatedFile from '@/views/admin/PreviewConsolidatedFile'

import Merchant from '@/views/admin/Merchant'
import Organism from '@/views/admin/Organism'

import CreateMerchant from '@/views/admin/CreateMerchant'
import CreateOrganism from '@/views/admin/CreateOrganism'

import ResetPassword from '@/components/utils/ResetPassword'

import Signup from '@/views/utils/Signup'

const UserRoutes = [
  {
    path: '/',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'analytics',
        component: Index,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/acquereurs',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'acquirersReport',
        component: Acquereurs,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/impayes',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'unpaidReport',
        component: Impayes,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/parametres',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'settings',
        component: Parametres,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/psp',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'pspReport',
        component: Psp,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/reconciliation',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdminPreviewing]),
    children: [
      {
        path: '',
        name: 'reconciliationReport',
        component: Reconciliation,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/consolidated-file',
    component: Layout,
    beforeEnter: multiguard([requireAuth]),
    children: [
      {
        path: ':id',
        name: 'consolidatedFiles',
        component: PreviewConsolidatedFile,
        meta: { requireAdmin: true },
      },
    ],
  },
]
const AdminRoutes = [
  {
    path: '/backoffice',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'IndexAdmin',
        component: IndexAdmin,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/merchants',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Marchands',
        component: Merchants,
        meta: { requireAdmin: true },
      },
      {
        path: ':merchant',
        name: 'Marchand',
        component: Merchant,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/organisms',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Organismes',
        component: Organisms,
        meta: { requireAdmin: true },
      },
      {
        path: ':organism',
        name: 'Organisme',
        component: Organism,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/users',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Utilisateurs',
        component: Users,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/files',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Fichiers',
        component: Files,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/currencies',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Devises',
        component: Devises,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/bin_mapping',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: '',
        name: 'Bin Mapping',
        component: BinMapping,
        meta: { requireAdmin: true },
      },
    ],
  },
  {
    path: '/backoffice/create',
    component: Layout,
    beforeEnter: multiguard([requireAuth, requireAdmin]),
    children: [
      {
        path: 'marchand',
        name: "Création d'un marchand",
        component: CreateMerchant,
        meta: { requireAdmin: true },
      },
      {
        path: 'organisme',
        name: "Création d'un organisme",
        component: CreateOrganism,
        meta: { requireAdmin: true },
      },
    ],
  },
]

const SharedRoutes = [
  {
    path: '/signin',
    name: 'Sign in',
    component: Signin,
    meta: { requireAdmin: false },
  },
  {
    path: '/profile',
    component: Layout,
    beforeEnter: multiguard([requireAuth]),
    children: [
      {
        path: '',
        name: 'Profil',
        component: UserProfile,
        meta: { requireAdmin: false },
      },
    ],
  },
  {
    path: '/account/password-reset/confirm/:uid/:key',
    component: ResetPassword,
    name: 'Reset Password',
  },
  {
    path: '/signup/invite/:key',
    component: Signup,
    name: 'Sign up',
  },
  { path: '*', redirect: '/' },
]

export default SharedRoutes.concat(AdminRoutes, UserRoutes)
