<template>
  <v-container
    class="dash pl-10"
    :style="{
      'padding-right': $store.state.filtersDrawerExpanded
        ? !$vuetify.breakpoint.smAndDown
          ? '320px'
          : '20px'
        : '12px',
    }"
    fluid
  >
    <core-app-bar />
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          dark
          color="white primary--text"
          class="d-print-none text-capitalize"
          @click="handleReconcile"
          :loading="isReconciling"
        >
          <v-icon>mdi-link-variant</v-icon>
          {{ $t('reconcile') }}
        </v-btn>

        <v-btn
          dark
          color="blue-grey lighten-2 ml-2"
          class="d-print-none text-capitalize"
          @click="handleToggleFilters(true)"
        >
          <v-icon> mdi-filter-outline</v-icon>
          {{ $t('filters') }}
        </v-btn>

        <v-btn
          color="primary"
          class="d-print-none text-capitalize ml-2"
          @click="print"
        >
          <v-icon left>mdi-printer-outline</v-icon>
          {{ $t('print') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <visualization
          card
          type="bar"
          query="psp_evolution_des_reconciliations_base_mensuelle"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('evolutionOfMonthlyReconciliations')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Date'],
            yaxis: {
              show: false,
            },
            yAxes: ['Reconciliations', 'Transactions non réconciliées'],
            colors: ['#2979ff', '#2979ff22'],
            chart: {
              stacked: true,
              stackType: '100%',
            },
            forecastDataPoints: {
              count: 1,
              fillOpacity: 0.5,
              dashArray: 4,
            },
            dataLabels: {
              enabledOnSeries: [0],
              style: {
                colors: ['#000'],
              },
              formatter(val, opt) {
                if (opt.seriesIndex === 0) {
                  return Math.round(val) + ' %'
                }
                const series = opt.w.config.series
                const idx = opt.dataPointIndex

                const total = series.reduce(
                  (total, self) => total + self.data?.[idx]?.y || 0,
                  0
                )
                return total
              },
            },
            tooltip: {
              shared: true,
              custom: function ({ series, dataPointIndex, w }) {
                return (
                  `<div class='apexcharts-tooltip-title' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px;'>
                    ${w.globals.seriesX?.[0]?.[dataPointIndex]}
                  </div>` +
                  [0, 1]
                    .map(
                      (_, i) => `
                        <div class='apexcharts-tooltip-series-group' style='order: 1; display: flex;'><span class='apexcharts-tooltip-marker'
                          style='background-color: ${w.globals.colors[i]};'></span>
                          <div class='apexcharts-tooltip-text' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px;'>
                            <div class='apexcharts-tooltip-y-group'><span class='apexcharts-tooltip-text-y-label'>${w.config.series[i].name}: </span>
                              <span class='apexcharts-tooltip-text-y-value'>${series[i][dataPointIndex]}</span>
                            </div>
                          </div>
                        </div>
                      `
                    )
                    .join('') +
                  `
                    <div class='apexcharts-tooltip-series-group' style='order: 2; display: flex;'>
                      <div class='apexcharts-tooltip-text' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: 600'>
                        <div class='apexcharts-tooltip-y-group'>
                          <span class='apexcharts-tooltip-text-y-label'>Total: </span>
                          <span class='apexcharts-tooltip-text-y-value'>${series
                            .map((s) => s[dataPointIndex])
                            .reduce((a, b) => a + b, 0)}</span>
                        </div>
                      </div>
                    </div>
                  `
                )
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: 'bottom',
                  hideOverflowingLabels: false,
                },
              },
            },
          }"
          :external-filters-values="specificFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" class="py-0">
        <v-row>
          <v-col lg="6">
            <v-switch
              :label="$t('displayAcceptanceToggleLabel')"
              v-model="showSelectCurrency"
              v-on:change="handleShowCurrency"
            ></v-switch>
          </v-col>
          <v-col lg="6" v-if="showSelectCurrency">
            <v-select
              :label="$t('currency')"
              v-model="currency"
              :items="currencies"
              v-on:change="handleSelectCurrency"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      :class="{
        'd-flex': showSelectCurrency,
        'align-center': showSelectCurrency,
      }"
    >
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          query="reconciliation_overview_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('acceptanceInDomesticCurrency')"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12" v-if="showSelectCurrency">
        <div
          class="d-flex justify-center flex-column align-center"
          v-if="!currency"
        >
          <v-icon size="100" class="mb-4 grey--text text--lighten-1">
            mdi-swap-horizontal
          </v-icon>
          <span class="text-center text-h5 grey--text text--lighten-1">
            {{ $t('chooseCurrencyFilter') }}
          </span>
        </div>
        <visualization
          card
          type="table"
          query="reconciliation_overview_tableau_settlement"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('acceptanceCurrency')"
          :external-filters-values="settlementCurrencyFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
          v-else
        />
      </v-col>
    </v-row>

    <FiltersDrawer
      :filters="filters()"
      @update="
        (v) => {
          globalFilters = v
        }
      "
      :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
    />
  </v-container>
</template>

<script>
import query from '@/services/QueryService.js'
import reconcileService from '@/services/ReconcileService.js'
import { camelCase, deburr, map } from 'lodash'
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import { palette1 } from '@/utils/theme-chart.js'
export default {
  name: 'Reconciliation',
  components: {
    FiltersDrawer,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },
  data() {
    return {
      globalFilters: [],
      paletteChart: palette1,
      showSelectCurrency: false,
      currencies: [],
      currency: null,
      isReconciling: false,
    }
  },
  mounted() {
    this.getCurrencies()
  },
  computed: {
    specificFilters() {
      let filters = this.filters()
      const globalFilters = this.globalFilters.reduce((cumul, curr) => {
        const found = filters.find((f) => f.name == curr.column)
        if (found) cumul[deburr(camelCase(found.label))] = curr
        return cumul
      }, {})
      const { periode, ...restOfFilters } = globalFilters
      return map(restOfFilters, (o) => o)
    },
    settlementCurrencyFilters() {
      let newCurrency = []

      const settlement_curr_filter = {
        column: 'currency_alphabetical_code',
        columnWrapper: undefined,
        operator: 'IN',
        value: [this.currency],
      }

      if (this.currency && this.showSelectCurrency) {
        newCurrency.push(...this.globalFilters, settlement_curr_filter)
      }

      return newCurrency
    },
    translations() {
      const keys = [
        'methodPaymentType',
        'authentificationStatus',
        'currencyVolumeAccepted',
        'acceptanceRateRaw',
        'acceptanceRateNetWithoutDup',
        'authorizationRate',
        'salesChannel',
        'country',
        'brand',
        'rate',
        'clues',
        'transactionVolumeInAcceptance',
        'volumeTransactions',
        'anomalyType',
      ]
      return Object.fromEntries(keys.map((k) => [this.$t(k, 'fr'), this.$t(k)]))
    },
  },
  methods: {
    adaptDatasetName(name) {
      return `consolidation_${name}_${this.$store.getters.merchantName
        .toLowerCase()
        .replace(/_(?=[1-9])/, '')}`
    },
    async print() {
      window.print()
    },
    filters() {
      const baseValues = {
        project: this.$store.getters.merchantId,
        dataset: this.adaptDatasetName('psp'),
        conditionName: 'conditions',
      }
      return [
        {
          name: 'transaction_service_type',
          type: 'list',
          multiple: true,
          label: this.$t('status', 'en'),
          values: {
            ...baseValues,
            column: 'transaction_service_type',
          },
        },
        {
          name: 'mp_type',
          type: 'list',
          multiple: true,
          label: this.$t('mopType', 'en'),
          values: {
            ...baseValues,
            column: 'mp_type',
          },
        },
        {
          name: 'brand',
          type: 'list',
          multiple: true,
          label: this.$t('brand', 'en'),
          values: {
            ...baseValues,
            column: 'brand',
          },
        },
        {
          name: 'sales_channel',
          type: 'list',
          multiple: true,
          label: this.$t('salesChannel', 'en'),
          values: {
            ...baseValues,
            column: 'sales_channel',
          },
        },
        {
          name: 'cccty',
          type: 'list',
          multiple: true,
          label: this.$t('country', 'en') + 'BIN',
          values: {
            ...baseValues,
            column: 'cccty',
          },
        },
        {
          name: 'country',
          type: 'country',
          multiple: true,
          label: this.$t('country', 'en'),
          values: {
            ...baseValues,
            column: 'country',
          },
        },
        {
          name: 'organism',
          type: 'list',
          multiple: true,
          label: this.$t('pspName', 'en'),
          values: {
            ...baseValues,
            column: 'organism',
          },
        },
        {
          name: 'full_date::date',
          type: 'daterange',
          multiple: true,
          label: this.$t('period', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'full_date::date',
            dataset: this.adaptDatasetName('psp'),
          },
          value: '',
        },
      ].reverse()
    },
    handleReconcile() {
      this.isReconciling = true
      reconcileService.reconcile(this.$store, this.$store.getters.merchantName).finally(() => {
        this.isReconciling = false
      })
    },
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },

    getCurrencies() {
      const querySettlementCurrency = `SELECT DISTINCT currency_alphabetical_code as "values" FROM consolidation_psp_${this.$store.getters.merchantName} WHERE currency_alphabetical_code <> '';`

      query
        .runAnon(
          this,
          this.$store.getters.merchantId,
          querySettlementCurrency,
          null
        )
        .then((r) => {
          r.data.rows.map((item) => {
            this.currencies.push(item.values)
          })
        })
    },
    handleSelectCurrency(v) {
      this.currency = v
    },
    handleShowCurrency(v) {
      this.showSelectCurrency = v
      this.currency = null
    },
  },
}
</script>
