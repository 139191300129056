import axios from "axios";

const BASE_URL =
  "https://analytics.quantus-bnc.com/api";

let config = {
  baseURL: BASE_URL,
  timeout: 180000
};

const HmdAPI = axios.create(config);
HmdAPI.interceptors.request.use(
  
  function(config) {
    const TOKEN =
    "Token " +
    (localStorage.getItem("token") ||
      localStorage.getItem("HMD_BACKEND_API_TOKEN") ||
      process.env.VUE_APP_HMD_API_TOKEN ||
      "");
    config.headers.authorization = TOKEN;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
HmdAPI.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default HmdAPI;
