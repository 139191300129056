export default {
  admin: [
    {
      to: '/backoffice',
      icon: 'mdi-view-dashboard',
      text: 'Accueil',
    },
    {
      to: '/backoffice/organisms',
      icon: 'mdi-domain',
      text: 'Organismes',
    },
    {
      to: '/backoffice/merchants',
      icon: 'mdi-shield-account-outline',
      text: 'Marchands',
    },
    {
      to: '/backoffice/users',
      icon: 'mdi-account-group-outline',
      text: 'Utilisateurs',
    },
    {
      to: '/backoffice/files',
      icon: 'mdi-content-copy',
      text: 'Fichiers',
    },
    {
      to: '/backoffice/currencies',
      icon: 'mdi-currency-eur',
      text: 'Devises',
    },
    {
      to: '/backoffice/bin_mapping',
      icon: 'mdi-bank-transfer',
      text: 'Bin Mapping',
    },
  ],
  default: [
    {
      to: '/',
      icon: 'mdi-home-outline',
      text: 'Accueil',
    },
    {
      to: '/acquereurs',
      icon: 'mdi-folder-outline',
      text: 'Acquéreurs',
    },
    {
      to: '/psp',
      icon: 'mdi-credit-card-outline',
      text: 'PSP',
    },
    {
      to: '/reconciliation',
      icon: 'mdi-link-variant',
      text: 'Reconciliation',
    },
    {
      to: '/impayes',
      icon: 'mdi-label-outline',
      text: 'Impayés',
    },
    // {
    //   to: '/parametres',
    //   icon: 'mdi-cog-outline',
    //   text: 'Paramètres',
    // },
    // ,
    // {
    //   to: "/personnaliser",
    //   icon: "mdi-selection-ellipse",
    //   text: "Personnaliser"
    // },
    // {
    //   to: "/files",
    //   icon: "mdi-content-copy",
    //   text: "Fichiers"
    // }
  ],
}
