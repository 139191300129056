import axios from 'axios'
import { logout } from './auth'
import { isArray, isPlainObject, join, isString } from 'lodash'

const HMD_BACKEND_API_URL = 'https://analytics.quantus-bnc.com/api'
const HTTP = axios.create({
  baseURL: HMD_BACKEND_API_URL,
  timeout: 900000,
})

HTTP.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('token')
    if (token != null) {
      config.headers.common.Authorization = 'Token ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
HTTP.interceptors.response.use(
  config => config,
  error => {
    const res = error.response
    if (res) if (res.status === 401) logout(null)
    return Promise.reject(error)
  }
)

const DefaultErrorHandler = ($, error, reject) => {
  var str = ''
  if (error instanceof Error) {
    if (error.response) {
      if ([500].includes(error.response.status)) {
        str += 'Erreur interne au serveur (error 500) \n'
      } else if ([404].includes(error.response.status))
        str += error.response.statusText + '\n'
      else {
        if (isString(error.response.data)) str += error.response.data + '\n'
        else if (isPlainObject(error.response.data))
          for (var el in error.response.data) {
            if (isPlainObject(error.response.data[el])) {
              str += Object.keys(error.response.data[el])
                .map(k => error.response.data[el][k])
                .join('\n')
            } else if (isArray(error.response.data[el]))
              str += error.response.data[el].join('\n')
            else str += error.response.data[el] + '\n'
            str += '\n'
          }
        else str += 'Erreur du serveur'
      }
    } else str += error.message + '\n'
  } else if (typeof b == 'string') {
    str += error + '\n'
  }
  $.$store.commit('error', {
    msg: str,
    color: 'error',
  })
  reject(error)
}
export { HTTP, DefaultErrorHandler, axios }
