/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler, axios } from '@/utils/http-common'

export default {
  reconcile($, project_id) {
    return new Promise((resolve, reject) => {
      HTTP.post('/bnc/reconciliations/', {
        target_project: project_id,
      })
        .then((response) => {
          if (response.data.updated_rows === 0) {
            $.commit('success', "Aucune réconciliation n'a été effectuée")
          } else {
            $.commit(
              'success',
              `${response.data.updated_rows} réconciliation(s) effectuée(s)`
            )
          }
          resolve(response)
        })
        .catch((err) => DefaultErrorHandler($, err, reject))
    })
  },
}
