var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"dash pl-10",style:({
    'padding-right': _vm.$store.state.filtersDrawerExpanded
      ? !_vm.$vuetify.breakpoint.smAndDown
        ? '320px'
        : '20px'
      : '12px',
  }),attrs:{"fluid":""}},[_c('core-app-bar'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"d-print-none text-capitalize",attrs:{"dark":"","color":"white primary--text","loading":_vm.isReconciling},on:{"click":_vm.handleReconcile}},[_c('v-icon',[_vm._v("mdi-link-variant")]),_vm._v(" "+_vm._s(_vm.$t('reconcile'))+" ")],1),_c('v-btn',{staticClass:"d-print-none text-capitalize",attrs:{"dark":"","color":"blue-grey lighten-2 ml-2"},on:{"click":function($event){return _vm.handleToggleFilters(true)}}},[_c('v-icon',[_vm._v(" mdi-filter-outline")]),_vm._v(" "+_vm._s(_vm.$t('filters'))+" ")],1),_c('v-btn',{staticClass:"d-print-none text-capitalize ml-2",attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer-outline")]),_vm._v(" "+_vm._s(_vm.$t('print'))+" ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('visualization',{attrs:{"card":"","type":"bar","query":"psp_evolution_des_reconciliations_base_mensuelle","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('evolutionOfMonthlyReconciliations'),"options":{
          noData: {
            text: _vm.$t('emptyData'),
          },
          xAxes: ['Date'],
          yaxis: {
            show: false,
          },
          yAxes: ['Reconciliations', 'Transactions non réconciliées'],
          colors: ['#2979ff', '#2979ff22'],
          chart: {
            stacked: true,
            stackType: '100%',
          },
          forecastDataPoints: {
            count: 1,
            fillOpacity: 0.5,
            dashArray: 4,
          },
          dataLabels: {
            enabledOnSeries: [0],
            style: {
              colors: ['#000'],
            },
            formatter(val, opt) {
              if (opt.seriesIndex === 0) {
                return Math.round(val) + ' %'
              }
              const series = opt.w.config.series
              const idx = opt.dataPointIndex

              const total = series.reduce(
                (total, self) => total + self.data?.[idx]?.y || 0,
                0
              )
              return total
            },
          },
          tooltip: {
            shared: true,
            custom: function ({ series, dataPointIndex, w }) {
              return (
                `<div class='apexcharts-tooltip-title' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px;'>
                  ${w.globals.seriesX?.[0]?.[dataPointIndex]}
                </div>` +
                [0, 1]
                  .map(
                    (_, i) => `
                      <div class='apexcharts-tooltip-series-group' style='order: 1; display: flex;'><span class='apexcharts-tooltip-marker'
                        style='background-color: ${w.globals.colors[i]};'></span>
                        <div class='apexcharts-tooltip-text' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px;'>
                          <div class='apexcharts-tooltip-y-group'><span class='apexcharts-tooltip-text-y-label'>${w.config.series[i].name}: </span>
                            <span class='apexcharts-tooltip-text-y-value'>${series[i][dataPointIndex]}</span>
                          </div>
                        </div>
                      </div>
                    `
                  )
                  .join('') +
                `
                  <div class='apexcharts-tooltip-series-group' style='order: 2; display: flex;'>
                    <div class='apexcharts-tooltip-text' style='font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: 600'>
                      <div class='apexcharts-tooltip-y-group'>
                        <span class='apexcharts-tooltip-text-y-label'>Total: </span>
                        <span class='apexcharts-tooltip-text-y-value'>${series
                          .map((s) => s[dataPointIndex])
                          .reduce((a, b) => a + b, 0)}</span>
                      </div>
                    </div>
                  </div>
                `
              )
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'bottom',
                hideOverflowingLabels: false,
              },
            },
          },
        },"external-filters-values":_vm.specificFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('displayAcceptanceToggleLabel')},on:{"change":_vm.handleShowCurrency},model:{value:(_vm.showSelectCurrency),callback:function ($$v) {_vm.showSelectCurrency=$$v},expression:"showSelectCurrency"}})],1),(_vm.showSelectCurrency)?_c('v-col',{attrs:{"lg":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('currency'),"items":_vm.currencies},on:{"change":_vm.handleSelectCurrency},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1):_vm._e()],1)],1)],1),_c('v-row',{class:{
      'd-flex': _vm.showSelectCurrency,
      'align-center': _vm.showSelectCurrency,
    }},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('visualization',{attrs:{"card":"","type":"table","query":"reconciliation_overview_tableau","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('acceptanceInDomesticCurrency'),"external-filters-values":_vm.globalFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1),(_vm.showSelectCurrency)?_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[(!_vm.currency)?_c('div',{staticClass:"d-flex justify-center flex-column align-center"},[_c('v-icon',{staticClass:"mb-4 grey--text text--lighten-1",attrs:{"size":"100"}},[_vm._v(" mdi-swap-horizontal ")]),_c('span',{staticClass:"text-center text-h5 grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$t('chooseCurrencyFilter'))+" ")])],1):_c('visualization',{attrs:{"card":"","type":"table","query":"reconciliation_overview_tableau_settlement","translations":_vm.translations,"project":_vm.$store.getters.merchantId,"title":_vm.$t('acceptanceCurrency'),"external-filters-values":_vm.settlementCurrencyFilters,"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null,"width":"100%","height":"auto"}})],1):_vm._e()],1),_c('FiltersDrawer',{attrs:{"filters":_vm.filters(),"forcedUser":_vm.$store.state.preview ? _vm.$store.state.preview.pk : null},on:{"update":(v) => {
        _vm.globalFilters = v
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }